<template>
  <div>
    <h2>{{ title }}</h2>
    <div
      v-for="(item, index) in sortedItems"
      :key="item.id"
      class="draggable-item"
      :class="{ group: item.type != 1, question: item.type == 3, test: item.type == 4 }"
    >
      <div class="item-header" :style="item.type !== 1 ? '' : 'font-weight:bold'">
        <span
          class="item-title"
          draggable="true"
          @dragstart="dragStart(index)"
          @dragover.prevent
          @drop="drop(index)"
          @dragend="dragEnd"
          @click="toggleExpand(index)"
        >
          <span class="title">
            <span v-if="item.title"> {{ item.title }}</span>
            <span v-if="item.title && item.lead"> - </span>
            <span v-if="item.lead">{{ item.lead }}</span></span
          >
          <span>
            <span>{{ index + 1 }} / {{ itemCount }}</span>
            <span class="expand-indicator">
              {{ isExpanded[index] ? "▼" : "▶" }}
            </span></span
          >
        </span>
        <div class="item-controls">
          <font-awesome-icon icon="trash" @click.stop="removeItem(index)" title="Usuń" />
          <font-awesome-icon
            icon="arrow-up"
            @click.stop="moveItemUp(index)"
            :class="{ disabled: index === 0 }"
            title="Przesuń w górę"
          />
          <font-awesome-icon
            icon="arrow-down"
            @click.stop="moveItemDown(index)"
            :class="{ disabled: index === sortedItems.length - 1 }"
            title="Przesuń w dół"
          />
        </div>
      </div>
      <ChildTodoItem
        v-if="isExpanded[index]"
        :item="item"
        @update-item="updateItem($event, index)"
      />
    </div>
  </div>
</template>

<script>
import ChildTodoItem from "./ChildTodoItem.vue";
import TodoChildServices from "../../services/TodoChildServices";

export default {
  components: {
    ChildTodoItem,
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
    title: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      localItems: [],
      isExpanded: [],
      draggedIndex: null,
      itemCount: 0,
      lock: false,
    };
  },
  computed: {
    sortedItems() {
      return [...this.localItems].sort((a, b) => a.order - b.order);
    },
  },
  watch: {
    items: {
      handler(newItems) {
        this.itemCount = newItems.length;
        this.localItems = newItems.map((item, index) => ({
          ...item,
          order: item.order ?? index,
        }));
        this.isExpanded = newItems.map(() => false);
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    toggleExpand(index) {
      this.isExpanded = this.isExpanded.map((_, i) =>
        i === index ? !this.isExpanded[i] : false
      );
    },
    removeItem(index) {
      TodoChildServices.deleteTodoChild(this.localItems[index].pageID).then(() => {
        this.localItems.splice(index, 1);
        this.isExpanded.splice(index, 1);
        this.reorderItems();
        this.emitUpdate();
      });
    },
    moveItemUp(index) {
      if (!this.lock) {
        if (index > 0) {
          this.swapOrder(index, index - 1);
          this.reorderItems();
          this.emitUpdate();
        }
      }
    },
    moveItemDown(index) {
      if (!this.lock) {
        if (index < this.sortedItems.length - 1) {
          this.swapOrder(index, index + 1);
          this.reorderItems();
          this.emitUpdate();
        }
      }
    },
    updateItem(updatedItem, index) {
      this.localItems[index] = updatedItem;
      this.emitUpdate(index);
    },
    emitUpdate(index) {
      this.$emit("update:items", [...this.sortedItems], index);
    },
    dragStart(index) {
      this.draggedIndex = index;
    },
    drop(index) {
      if (this.draggedIndex !== index) {
        const draggedItem = this.localItems.splice(this.draggedIndex, 1)[0];
        this.localItems.splice(index, 0, draggedItem);
        const draggedExpandedState = this.isExpanded.splice(this.draggedIndex, 1)[0];
        this.isExpanded.splice(index, 0, draggedExpandedState);
        this.reorderItems();
        this.emitUpdate();
      }
      this.draggedIndex = null;
    },
    dragEnd() {
      this.draggedIndex = null;
    },
    swapOrder(index1, index2) {
      var item1 = this.localItems[index1];
      var item2 = this.localItems[index2];
      this.localItems[index1] = item2;
      this.localItems[index2] = item1;
    },
    reorderItems() {
      var idsChange = [];
      this.lock = true;
      this.localItems.forEach((item, index) => {
        idsChange.push(item.pageID);
        item.order = index;
      });
      TodoChildServices.reOrder(this.localItems[0].toDoID, idsChange).then(() => {
        this.lock = false;
      });
    },
  },
};
</script>

<style scoped>
.draggable-item {
  margin: 5px 0;
  border: 1px solid #ddd;
  border-radius: 3px;
}

.item-header {
  padding: 10px;
  background: #f9f9f9;
  border-bottom: 1px solid #ddd;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.item-header .item-title {
  flex-grow: 1;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.item-header .item-title span {
  padding: 6px;
}
.item-header .expand-indicator {
  margin-left: 10px;
}

.item-controls {
  display: flex;
  align-items: center;
}

.item-controls svg {
  cursor: pointer;
}
.item-controls svg {
  padding: 6px;
}

.item-controls .disabled {
  cursor: not-allowed;
  color: #ccc;
}

.form-group {
  margin-top: 10px;
}

.form-group button {
  padding: 10px 15px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.form-group button:hover {
  background-color: #0056b3;
}

.btn-primary {
  background-color: #007bff;
  border-color: #007bff;
  color: #fff;
  border-radius: 4px;
  padding: 10px 15px;
  cursor: pointer;
}

.btn-primary:hover {
  background-color: #0056b3;
  border-color: #0056b3;
}
.svg-inline--fa {
  min-height: 25px;
  min-width: 25px;
}
.group {
  margin-left: 15px;
}
.question {
  margin-left: 25px;
}
.question .title {
  color: green;
}
.test {
  margin-left: 35px;
}
.test .title {
  color: red;
}
</style>
