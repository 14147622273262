<template>
  <div>
    <div v-if="content">
      <h1>{{ content.title }}</h1>
      <DraggableList
        :items="items"
        @update:items="updateItems"
        ref="dragable"
      ></DraggableList>
      <button @click="addItem" :disabled="lockAdd">Dodaj nowy element</button>
    </div>
  </div>
</template>

<script>
import TodoService from "../services/TodoService";
import TodoChildServices from "../services/TodoChildServices";
import DraggableList from "./SubComponents/DraggableList.vue";

export default {
  components: {
    DraggableList,
  },
  props: {
    id: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      content: null,
      items: [],
      lockAdd: false,
    };
  },
  beforeMount() {
    this.getTodo();
  },
  computed: {
    sortedItems() {
      return [...this.localItems].sort((a, b) => a.order - b.order);
    },
  },
  methods: {
    getTodo() {
      TodoService.getTodoItem(this.id).then(
        (response) => {
          this.content = response.data;
          this.getChild();
        },
        (error) => {
          this.content =
            (error.response && error.response.data && error.response.data.message) ||
            error.message ||
            error.toString();
        }
      );
    },
    getChild() {
      TodoChildServices.getChildItems(this.id).then(
        (response) => {
          this.items = response.data
            .sort((a, b) => a.order - b.order)
            .filter((item) => item.active);
        },
        (error) => {
          this.content =
            (error.response && error.response.data && error.response.data.message) ||
            error.message ||
            error.toString();
        }
      );
    },
    updateItems(newItems, index) {
      this.items = newItems;
      if (index) {
        this.lockAdd = !newItems[index].isNew;
        setTimeout(() => {
          this.$refs.dragable.toggleExpand(index);
        }, 0);
        newItems[index].isNew = undefined;
      }
    },
    addItem() {
      this.items.push({
        pageID: 0,
        toDoID: this.id,
        title: "",
        lead: "",
        fileID: 0,
        a1: "",
        a1Correct: 0,
        a2: "",
        a2Correct: 0,
        a3: "",
        a3Correct: 0,
        a4: "",
        a4Correct: 0,
        a5: "",
        a5Correct: 0,
        a6: "",
        a6Correct: 0,
        text: "",
        html: false,
        type: 2,
        order: this.items.length,
        active: true,
      });
      this.lockAdd = true;
      setTimeout(() => {
        this.$refs.dragable.toggleExpand(this.items.length - 1);
      }, 0);
    },
  },
};
</script>

<style scoped>
button {
  margin-top: 10px;
  padding: 10px 15px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}
button:hover {
  background-color: #0056b3;
}
</style>
