import axios from 'axios';
const API_URL = process.env.VUE_APP_BASE_API_URL;

class TodoChildSerivece {
    getChildItems(id) {
        return axios.get(`${API_URL}ToDoItemsChild/${id}`);
    }
    saveChildItems(data) {
        return axios.put(`${API_URL}ToDoItemsChild`, data);
    }
    addChildItems(data) {
        return axios.post(`${API_URL}ToDoItemsChild`, data);
    }
    deleteTodoChild(id) {
        return axios.delete(`${API_URL}ToDoItemsChild/${id}`);
    }
    reOrder(id, data) {
        return axios.put(`${API_URL}ToDoItemsChild/${id}`, data)
    }
}

export default new TodoChildSerivece();
